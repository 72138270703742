<template>
	<!-- 表单提交成功的最后一个页面 -->
	<div class="finishBox">
		<div class="finish">
				<div class="topBox">
				<h1>Congratulations</h1>
				<div class="title">You warranty was successfully submitted !</div>
				<div class="explain">If there's anything else we can do for you, please don't hesitate to let us know.</div>
				<div class="content">
					<div class="iconImg">
					  <img src="../assets/icon/frame.png"/>
					</div>
					<div class="email">Email: <a href="mailto:info@simrexstore.com">info@simrexstore.com</a></div>
				</div>
			</div>
			<div class="go-form" @click="toNewForm">Fill out a new form</div>
		</div>
	</div>
</template>

<script>
export default {
  methods: {
    toNewForm() {
      const vm = this
      const f = vm.$route.query.f
      let path
      switch (f) {
			case '1':
				path = '/warranty'
				break
			// case '2':
			// 	path = '/nc9'
			// 	break
			// case '3':
			// 	path = '/t12'
			// 	break
			// case '4':
			// 	path = '/a1'
			// 	break
		 //   case '5':
			// 	path = '/buds'
			// 	break
      }
      vm.$router.push(path)
    }
  }
}
</script>

<style lang='less' scoped>
	
.finishBox{
	width: 100%;
	height: 100%;
	min-height: 100vh;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	@media (min-width: 769px) {
		padding: 20px;
		background: #000000;
	}
	@media (max-width: 768px) {
		padding: 5px 5px 5px 5px;
		background-image: url('../assets/icon/bg.png');
	}
}

.finish {
	box-sizing: border-box;
	width: 100%;
   max-width: 772px;
	text-align: center;
	
	.topBox{
		border-radius: 8px;
		background-color: #fff;
		@media (min-width: 769px) {
			padding: 70px 20px;
		}
		@media (max-width: 768px) {
			padding: 55px 18px;
		}
	}
	
	h1 {
		color: #000000;
		font-size: 28px;
		@media (max-width: 768px) {
			font-size: 28px;
		}
	}
	
	.title {
		font-size: 18px;
		margin-top: 22px;
		line-height: 1.5;
		color: #3A3A3A;
		@media (max-width: 768px) {
			font-size: 20px;
		}
	}
	
	.explain {
		font-size: 18px;
		margin-top: 50px;
		color: #3A3A3A;
		line-height: 1.5;
		@media (max-width: 768px) {
			font-size: 20px;
			margin: auto;
			margin-top: 30px;
			max-width: 350px;
			font-size: 18px;
			margin-top: 60px;
		}
	}
	
	.content {
		margin-top: 40px;
		@media (max-width: 768px) {
			margin-top: 30px;
		}
		.icon {
			width: 40px;
			height: 40px;
			margin: 0 auto;
			text-align: center;
			line-height: 43px;
			background-color: rgb(189, 182, 182);
			border-radius: 50%;
		}
		.iconImg{
			width: 100%;
			text-align: center;
			img{
				width: 100%;
				max-width: 50px;
			}
		}
		.email {
			color: #3A3A3A;
			font-size: 18px;
			margin-top: 5px;
			a{
				color: #3A3A3A;
				text-decoration: underline;
				text-decoration-color: #3A3A3A;
			}
		}
	}
	
	.go-form {
		color: #ffffff;
		cursor: pointer;
		/* text-decoration: underline; */
		/* text-decoration-color: #ffffff; */
		margin: auto;
		margin-top: 50px;
		font-size: 16px;
		font-weight: 500;
		padding: 10px 20px;
		border: 1px solid #ffffff;
		border-radius:50px;
		max-width: 200px;
		@media (max-width: 768px) {
			margin-top: 30px;
		}
	}
}
</style>
